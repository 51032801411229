<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="div-footer">
    <div class="container">
      <div class="div row">
        <div class="col-md-4 text-right">
          <div class="links tajawal-medium">روابط هامة</div>
          <div class="d-flex gap-70">
            <ul>
              <li>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f878102cb6bd8040a3ac270467f28783d98f111280a1d415101c5dbde7dc08a6?"
                  class="img-footer"
                />
                <router-link to="/" class="tajawal-medium"
                  >الرئيسية</router-link
                >
              </li>
              <li>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f878102cb6bd8040a3ac270467f28783d98f111280a1d415101c5dbde7dc08a6?"
                  class="img-footer"
                />
                <a class="tajawal-medium">الإهداءات</a>
              </li>
              <li>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f878102cb6bd8040a3ac270467f28783d98f111280a1d415101c5dbde7dc08a6?"
                  class="img-footer"
                />
                <a class="tajawal-medium">اهداءات لمساجد مكه </a>
              </li>
            </ul>
            <ul>
              <li>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f878102cb6bd8040a3ac270467f28783d98f111280a1d415101c5dbde7dc08a6?"
                  class="img-footer"
                />
                <router-link to="/contact-us" class="tajawal-medium"
                  >تواصل معنا</router-link
                >
              </li>
              <li>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f878102cb6bd8040a3ac270467f28783d98f111280a1d415101c5dbde7dc08a6?"
                  class="img-footer"
                />
                <router-link to="/profile" class="tajawal-medium"
                  >الملف الشخصي</router-link
                >
              </li>
              <li>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f878102cb6bd8040a3ac270467f28783d98f111280a1d415101c5dbde7dc08a6?"
                  class="img-footer"
                />
                <router-link to="/about-us" class="tajawal-medium"
                  >أعمالنا</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 text-right contact">
          <div class="links tajawal-medium">بيانات التواصل</div>
          <div class="d-flex gap-70">
            <ul>
              <li class="d-flex">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f1a769d3370cfcbdde852c6734caa039602422058dc8c3cebe2d7a1589af884?"
                  class="img-4"
                />
                <div class="div-8 tajawal-medium">الباحة – محافظة المندق</div>
              </li>
              <li class="d-flex">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b510d59a1ed70c1e7ee15ffabd9028c31e1e102e50687cf6079d4e0b5e627c9?"
                  class="img-5"
                />
                <div class="div-9">
                  <a href="tel:+966555057834"> 7834 505 55 966+ </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 text-right contact">
          <div class="links tajawal-medium">وسائل الدفع</div>
          <img class="pay-method" src="../assets/img/payment.png" />
        </div>
      </div>
      <div class="row d-lg-block d-none">
        <div class="div-b-footer">
          <div class="div-2-b-footer">
            <div class="div-3-b-footer">
              <div class="div-4-b-footer">الإهداء الذكي</div>
              <div class="div-5-b-footer">
                نهدف إلي التسهيل التام على كل راغب في إهداء عبوات الماء بطريقة
                مضمونة ويسيرة
              </div>
            </div>
            <div class="div-6-b-footer">
              <div class="div-7-b-footer">
                <router-link to="/privacy">سياسة الخصوصية</router-link>
              </div>
              <div class="div-8-b-footer">
                <router-link to="/terms">الشروط والأحكام</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-block d-md-none">
        <div class="div-6-b-footer col-12">
          <div class="div-7-b-footer">
            <router-link to="/privacy">سياسة الخصوصية</router-link>
          </div>
          <div class="div-8-b-footer">
            <router-link to="/terms">الشروط والأحكام</router-link>
          </div>
        </div>
        <div class="div-3-b-footer col-12">
          <div class="div-4-b-footer">الإهداء الذكي</div>
          <div class="div-5-b-footer">
            نهدف إلي التسهيل التام على كل راغب في إهداء عبوات الماء بطريقة
            مضمونة ويسيرة
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pay-method {
  width: 270px;
  height: 165px;
}
.div-footer {
  padding-top: 50px;
  justify-content: flex-end;
  background-color: #0c569c;
  display: flex;
  flex-direction: column;
  color: #fff;
}
@media (max-width: 991px) {
  .div-footer {
    padding-right: 10px;
  }
}
style > .div-footer {
  display: flex;
  max-width: 340px;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.div-2-footer {
  justify-content: flex-end;
  align-items: end;
  border-color: rgba(255, 255, 255, 1);
  border-style: dashed;
  border-bottom-width: 1px;
  width: 97%;
  padding: 0 0px 25px;
  font: 22px/90% Tajawal, sans-serif;
}
.div-3-footer {
  display: flex;
  margin-top: 18px;
  gap: 20px;
  font-size: 20px;
  text-align: right;
  line-height: 155%;
}
.div-4-foooter {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
}

.img-footer {
  aspect-ratio: 0.31;
  object-fit: auto;
  object-position: center;
  width: 6px;
}
.gap-70 {
  gap: 70px;
}
.links {
  border-bottom: 0.1px dotted #ddd;
  border-color: #ddd;
  border-style: dashed;
  border-width: 0;
  border-bottom-width: 0.1px;
  width: 97%;
  padding: 0 0px 25px;
  font: 22px/90% Tajawal, sans-serif;
  justify-content: flex-end;
  align-items: end;
  margin-bottom: 20px;
}
.div-footer li {
  padding-bottom: 10px;
}
.div-footer li a,
.div-footer li {
  padding-right: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
}
.div-footer .contact li {
  display: flex;
  gap: 5px;
}
.div-b-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #0c569c;
  display: flex;
  flex-direction: column;
  color: #fff;
  letter-spacing: -0.5px;
  padding: 50px 60px 24px;
}
@media (max-width: 991px) {
  .div-b-footer {
    padding: 0 20px;
  }
}
.div-2-b-footer {
  display: flex;
  margin-left: 109px;
  width: 892px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .div-2-b-footer {
    flex-wrap: wrap;
  }
}
.div-3-b-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 991px) {
  .div-3-b-footer {
    max-width: 100%;
    margin: 50px 0px;
  }
}
.div-4-b-footer {
  leading-trim: both;
  text-edge: cap;
  align-self: center;
  font: 700 17px/101% Tajawal, sans-serif;
}
.div-5-b-footer {
  leading-trim: both;
  text-edge: cap;
  margin-top: 22px;
  font: 500 15px/114% Tajawal, sans-serif;
}
@media (max-width: 991px) {
  .div-5-b-footer {
    max-width: 100%;
  }
}
.div-6-b-footer {
  display: flex;
  gap: 17px;
  font-size: 15px;
  font-weight: 500;
  line-height: 114%;
  margin: auto 0;
}
.div-7-b-footer {
  leading-trim: both;
  text-edge: cap;
  font-family: Tajawal, sans-serif;
  flex-grow: 1;
}
.div-8-b-footer {
  leading-trim: both;
  text-edge: cap;
  font-family: Tajawal, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}
.div-footer a {
  color: #fff !important;
}
</style>
